<template>
    <div class="d-flex align-items-center justify-content-center" style="height: calc(100vh - 44px)">
        <div class="row">
            <input class="form-control mr-1 bg-white" type="number" v-model="idutilisateur">
        </div>
        <div class="row">
            <button class="form-control btn btn-success" @click="impersonate()">Valider</button>
        </div>
    </div>
</template>

<script>

import authenticationService from "@/services/authenticationService";
import store from "@/store";
import router from "@/router";
import AlertHandler from '@/alertHandler'

export default {
    name: 'login',
    data() {
        return {
            idutilisateur: null
        }
    },
    mounted() {
    },
    methods: {
        impersonate() {
            authenticationService
                .impersonate(this.idutilisateur)
                .then(res => {
                    if (res.data.data) {
                        store.commit('UPDATE_USER', res.data.data)
                        router.push({name: 'prestations'})
                    } else {
                        AlertHandler.show({code: 4018})
                    }
                })
                .catch(() => {
                        AlertHandler.show({code: 4019})
                });
        }
    }
}
</script>

<style scoped>

</style>
